import { storyblokEditable } from "@storyblok/react";
import { FC } from "react";
import { StoryblokRichtext } from "storyblok-rich-text-react-renderer";
import { Paragraph } from "ui";
import { StoryBaseProps } from "../types/StoryBaseProps";
import { RichTextRenderer } from "./RichTextRenderer";

interface StoryParagraphProps
	extends StoryBaseProps<{
		value?: StoryblokRichtext;
	}> {}

export const StoryParagraph: FC<StoryParagraphProps> = ({ blok }) => {
	const { value, _uid } = blok;
	return (
		<>
			<Paragraph {...storyblokEditable(blok)} renderedDirect={true} key={_uid}>
				<RichTextRenderer value={value} />
			</Paragraph>
		</>
	);
};
