import Link from "next/link";
import { FC } from "react";
import { MARK_LINK, NODE_LI, render, StoryblokRichtext } from "storyblok-rich-text-react-renderer";

interface RichTextRendererProps {
	value: StoryblokRichtext;
}
export const RichTextRenderer: FC<RichTextRendererProps> = ({ value }) => {
	return (
		<>
			{render(value, {
				markResolvers: {
					[MARK_LINK]: (children, props) => {
						const { linktype, href, target } = props;
						if (linktype === "email") {
							// Email links: add `mailto:` scheme and map to <a>
							return <a href={`mailto:${href}`}>{children}</a>;
						}
						if (href.match(/^(https?:)?\/\//)) {
							// External links: map to <a>
							return (
								<a href={href} target={target}>
									{children}
								</a>
							);
						}
						// Internal links: map to <Link>
						return <Link href={href}>{children}</Link>;
					},
				},
				nodeResolvers: {
					[NODE_LI]: (children) => {
						if (children?.[0]?.props?.children?.[0]) {
							return <li>{children?.[0]?.props?.children?.[0]}</li>;
						}
						return <li>{children}</li>;
					},
				},
			})}
		</>
	);
}